<template>
<div id="second">

  <div class="hidden-sm-and-down">
    <PageHeader/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <DepartmentAside aside-color="#70c9b5" title="セカンドオピニオン">
          <div class="detail1">
            <div class="content1">
              <div class="title1">セカンドオピニオン外来について</div>
              <div class="text">現在、他の医療機関にて診療を受けておられる患者さんで、診断内容や治療法について当クリニックの 太田医師 の意見をお求めになりたい方は、がん専門（特に消化器系）の意見・判断を提供いたします。</div>
              <div class="text">当クリニックのセカンドオピニオン外来は、患者さん自身およびその家族が疾患を理解して、治療を選択し、納得した治療を受けていただくための手助けを目的としています。</div>
            </div>
            <div class="content1">
              <div class="minitext">ご注意</div>
              <div class="minitext">セカンドオピニオン外来では相談のみを扱い、診療行為（検査・投薬・治療等）は行いません。また、当院への転院窓口ではありません。当院での検査や治療をご希望の場合は、セカンドオピニオン外来の対象とはなりませんので、当院で治療を希望する旨を書かれた紹介状をお持ちになって、一般外来を受診してください。</div>
            </div>
            <div class="content1">
              <div class="title1">セカンドオピニオンをお受けできない場合</div>
              <div class="text">
                <ul>
                  <li>転医、転院を最初から希望されている場合</li>
                  <li>セカンドオピニオン外来時に、当院での治療等診療行為を希望される場合</li>
                  <li>現在治療を受けている医療機関、医師に対する不満、医療訴訟等に関するご相談</li>
                  <li>医療費、医療給付の内容に関するご相談</li>
                  <li>死亡した患者さんを対象とするご相談</li>
                  <li>主治医が了承していない場合</li>
                  <li>相談領域に対応できる専門医が当院にいない場合</li>
                  <li>当院から指定された相談に必要な資料（診療情報提供書、放射線画像、検査記録等）をお持ちでない場合</li>
                  <li>予約外で来院された場合</li>
                </ul>
              </div>
            </div>
            <div class="content1">
              <div class="title1">費用・日時等</div>
              <div class="table1">
                <el-descriptions border :column="1" size="medium">
                  <el-descriptions-item>
                    <template slot="label">費用</template>
                    <div>1相談・1診療科60分を限度として30,000円（消費税込み）</div>
                    <div class="minitext">※全額自費（健康保険は適用されません）</div>
                    <div class="minitext">※日本語のみ</div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">相談者の方</template>
                    <div>原則的に患者さんご本人ですが、患者さんから同意を得たご家族の方を含めます。</div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">ご相談日時</template>
                    <div>日程を調整の上、連絡いたします。</div>
                    <div class="minitext">※連絡まで数日かかる場合もございますので、ご了承ください。</div>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">必要資料等</template>
                    <div>
                      <ul>
                        <li><span class="link" @click="download(applyFile, '申込書.pdf')">申込書</span>(PDF：190KB)</li>
                        <li><span class="link" @click="download(questionFile2, '問診票.pdf')">問診票</span>(PDF：106KB)</li>
                        <li>診療情報提供書(主治医作成のもの、当院宛、診療科指定のあるもの）</li>
                        <li>放射線画像（CD-ROM）
                          <div class="minitext">※フィルムの場合はあらかじめお申し出ください。</div>
                        </li>
                        <li>検査記録、病理標本等
                          <div class="minitext">※病理標本（プレパラート）をお持ちの方は、「病理組織検体報告書」をご同封下さい。</div>
                        </li>
                      </ul>
                    </div>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
            <div class="content1">
              <div class="title1">申込方法</div>
              <div class="text">
                <ol>
                  <li>お申し込み
                    <div>このページの内容をお読みいただき、ご了承いただけましたら「 <span class="link" @click="download(applyFile, '申込書.pdf')">申込書</span> (PDF：190KB)」と「 <span class="link" @click="download(questionFile4, '問診票.pdf')">問診票</span> (115KB：PDF)」に必要事項をご記入の上、診療情報提供書（紹介状）と一緒に郵送（下記宛先）、またはご持参（受付時間：午前9時00分～午後4時00分）ください。</div>
                  </li>
                  <li>日程のご連絡
                    <div>相談日時を調整の上、お電話で連絡いたします。</div>
                    <div>※お申し込みから相談実施日まで7日～10日程度の日数をいただいております。病理標本（プレパラート）をご提出の場合や専門医の都合によりましてはさらにお待ちいただくこともございますので、あらかじめご了承ください。</div>
                  </li>
                  <li>セカンドオピニオン当日
                    <div>当日は15分前迄に当クリニックまでお越しください。</div>
                  </li>
                  <li>セカンドオピニオン終了後
                    <div>会計窓口でお会計後、お帰りいただきます。専門医が報告書を作成し、後日、患者さんおよび紹介医宛に郵送にてお送りいたします。</div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </DepartmentAside>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-md-and-up">
    <m-top/>

    <div class="m-title">セカンドオピニオン</div>
    <div class="m-main">
      <div class="m-detail">
        <div class="m-subtitle">セカンドオピニオン外来について</div>
        <div class="m-content">現在、他の医療機関にて診療を受けておられる患者さんで、診断内容や治療法について当クリニックの 太田医師 の意見をお求めになりたい方は、がん専門（特に消化器系）の意見・判断を提供いたします。</div>
        <div class="m-content">当クリニックのセカンドオピニオン外来は、患者さん自身およびその家族が疾患を理解して、治療を選択し、納得した治療を受けていただくための手助けを目的としています。</div>
      </div>
      <div class="m-detail notice">
        <div class="minitext">ご注意</div>
        <div class="minitext">セカンドオピニオン外来では相談のみを扱い、診療行為（検査・投薬・治療等）は行いません。また、当院への転院窓口ではありません。当院での検査や治療をご希望の場合は、セカンドオピニオン外来の対象とはなりませんので、当院で治療を希望する旨を書かれた紹介状をお持ちになって、一般外来を受診してください。</div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">セカンドオピニオンをお受けできない場合</div>
        <div class="m-content">
          <ul>
            <li>転医、転院を最初から希望されている場合</li>
            <li>セカンドオピニオン外来時に、当院での治療等診療行為を希望される場合</li>
            <li>現在治療を受けている医療機関、医師に対する不満、医療訴訟等に関するご相談</li>
            <li>医療費、医療給付の内容に関するご相談</li>
            <li>死亡した患者さんを対象とするご相談</li>
            <li>主治医が了承していない場合</li>
            <li>相談領域に対応できる専門医が当院にいない場合</li>
            <li>当院から指定された相談に必要な資料（診療情報提供書、放射線画像、検査記録等）をお持ちでない場合</li>
            <li>予約外で来院された場合</li>
          </ul>
        </div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">費用・日時等</div>
        <div class="m-content">
          <el-descriptions border :column="1" size="medium">
            <el-descriptions-item>
              <template slot="label">費用</template>
              <div>1相談・1診療科60分を限度として30,000円（消費税込み）</div>
              <div class="minitext">※全額自費（健康保険は適用されません）</div>
              <div class="minitext">※日本語のみ</div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">相談者の方</template>
              <div>原則的に患者さんご本人ですが、患者さんから同意を得たご家族の方を含めます。</div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">ご相談日時</template>
              <div>日程を調整の上、連絡いたします。</div>
              <div class="minitext">※連絡まで数日かかる場合もございますので、ご了承ください。</div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">必要資料等</template>
              <div>
                <ul>
                  <li><span class="link" @click="download(applyFile, '申込書.pdf')">申込書</span>(PDF：190KB)</li>
                  <li><span class="link" @click="download(questionFile2, '問診票.pdf')">問診票</span>(PDF：106KB)</li>
                  <li>診療情報提供書(主治医作成のもの、当院宛、診療科指定のあるもの）</li>
                  <li>放射線画像（CD-ROM）
                    <div class="minitext">※フィルムの場合はあらかじめお申し出ください。</div>
                  </li>
                  <li>検査記録、病理標本等
                    <div class="minitext">※病理標本（プレパラート）をお持ちの方は、「病理組織検体報告書」をご同封下さい。</div>
                  </li>
                </ul>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="m-detail">
        <div class="m-subtitle">申込方法</div>
        <div class="m-content">
          <ol>
            <li>お申し込み
              <div>このページの内容をお読みいただき、ご了承いただけましたら「 <span class="link" @click="download(applyFile, '申込書.pdf')">申込書</span> (PDF：190KB)」と「 <span class="link" @click="download(questionFile4, '問診票.pdf')">問診票</span> (115KB：PDF)」に必要事項をご記入の上、診療情報提供書（紹介状）と一緒に郵送（下記宛先）、またはご持参（受付時間：午前9時00分～午後4時00分）ください。</div>
            </li>
            <li>日程のご連絡
              <div>相談日時を調整の上、お電話で連絡いたします。</div>
              <div>※お申し込みから相談実施日まで7日～10日程度の日数をいただいております。病理標本（プレパラート）をご提出の場合や専門医の都合によりましてはさらにお待ちいただくこともございますので、あらかじめご了承ください。</div>
            </li>
            <li>セカンドオピニオン当日
              <div>当日は15分前迄に当クリニックまでお越しください。</div>
            </li>
            <li>セカンドオピニオン終了後
              <div>会計窓口でお会計後、お帰りいただきます。専門医が報告書を作成し、後日、患者さんおよび紹介医宛に郵送にてお送りいたします。</div>
            </li>
          </ol>
        </div>
      </div>
    </div>

    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
export default {
  name: "Kampo",
  data() {
    return {
      applyFile: './secondFile/mousikomisyo-1.pdf',
      questionFile2: './secondFile/monshinhyou-2.pdf',
      questionFile4: './secondFile/monshinhyou-4.pdf'
    }
  },
  methods: {
    download(path, title) {
      let a = document.createElement('a')
      a.setAttribute('href', path)
      a.setAttribute('download', title)
      document.body.appendChild(a)
      a.click()
    }
  },
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 30px;
}
.content1 .title1 {
  color: #e88383;
  margin-bottom: 10px;
}
.content1 .text {
  color: #535353;
  line-height: 1.5;
}
.minitext {
  color: #707070;
  font-size: 15px;
  line-height: 1.5;
}
ul {
  margin-left: 1rem;
  list-style-type: disc;
}
ol {
  margin-left: 1.5rem;
  list-style-type: decimal;
}
ol li {
  margin-bottom: 10px;
}
.table1 {
  margin-top: 1rem;
}
.table1 /deep/ th, .table1 /deep/ td {
  font-size: 16px;
}
.table1 /deep/ ul {
  margin-left: 1.5rem;
}
/*点击下载pdf文件*/
.link {
  color: #62b6e2;
  cursor: pointer;
}
.link:hover {
  color: rgba(98, 182, 226, 0.8);
}

/*手机版*/
.m-title {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #C34668;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #333;
}
.notice {
  padding-top: 1rem;
}
</style>
